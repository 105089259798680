import React from "react";
import Footer from "../footer/footer";
import Header from "../header-public/header-public";

function LayoutPublic(props) {
  return (
    <>
      <Header />
      <>{props?.children ? props?.children : null}</>
      <Footer />
    </>
  );
}

export default LayoutPublic;
