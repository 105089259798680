import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { Container, Text, Flex, Box, Button, Center } from "@chakra-ui/react";
import Card from "../../components/card/card";
import { useHistory } from "react-router-dom";
import service from "../../sdk";

function PageNotFound() {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    service.trackPageView({
      documentTitle: "Page not found",
    });
  }, []);

  const handleOnClickMatomo = (action, name) => {
    service.trackEvent({
      category: "Page not found",
      action: action,
      name: name,
    });
  };

  return (
    <Container>
      <Helmet>
        <title>Page not found</title>
      </Helmet>
      <Center height="100vh">
        <Box p="16px" maxW="800px" w="100%" overflow="hidden">
          <Card>
            <Box
              p="10"
              pb="16"
              w="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              textAlign="center"
            >
              <Text
                fontSize="50px"
                fontWeight="550"
                color="#14213D"
                textTransform="uppercase"
                id="page-not-found"
              >
                404
              </Text>

              <Text mt="4" fontWeight="500">
                {t("Oops… We can’t find the page you’re looking for.")}
              </Text>

              <Box>
                <Flex justifyContent="center">
                  <Button
                    onClick={() => {
                      history.push("/");
                      handleOnClickMatomo("tap", "Go Home Button");
                    }}
                    textTransform="uppercase"
                    id="continue-button"
                    mt="10"
                    fontWeight="bold"
                    borderRadius="20px"
                    bg="#14213D"
                    color="white"
                    variant="solid"
                    minW="120px"
                    pl="10"
                    pr="10"
                    _hover={{ bg: "#14213D" }}
                  >
                    {t("Go Home")}
                  </Button>
                </Flex>
              </Box>
            </Box>
          </Card>
        </Box>
      </Center>
    </Container>
  );
}

export default PageNotFound;
