import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./english/english.json";
import kh from "./khmer/khmer.json";

i18next.use(initReactI18next).init({
  debug: false,
  lng: "kh",
  fallbackLng: "en",
  ns: ["common"],
  defaultNS: "common",
  resources: { kh, en },
  interpolation: { escapeValue: false },
  react: { useSuspense: true },
  keySeparator: ">",
  nsSeparator: "|",
});

export default i18next;
