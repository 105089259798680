import React, { useEffect } from "react";
import "./App.css";
import i18next from "./i18n";
import Routes from "./routes";
import { HelmetProvider } from "react-helmet-async";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import client from "./apollo/client";
import { ApolloProvider } from "@apollo/client";

function App() {
  useEffect(() => {
    const lang = localStorage.getItem("language");
    // const lang = "kh";
    if (lang !== null) {
      i18next.changeLanguage(lang);
    } else {
      i18next.changeLanguage("kh");
    }
  }, []);

  const theme = extendTheme({
    fonts: {
      heading: "Open Sans",
      body: "Rajdhani, Kantumruy, sans-serif",
    },
    styles: {
      global: {
        // styles for the `body`
        body: {
          bg: "#F4F4F4",
        },
        p: {
          color: "#727272",
        },
      },
    },
    colors: {
      blueNavy: {
        500: "#14213D",
      },
    },
  });

  return (
    <React.Suspense fallback={null}>
      <ApolloProvider client={client}>
        <HelmetProvider>
          <ChakraProvider theme={theme}>
            <Routes />
          </ChakraProvider>
        </HelmetProvider>
      </ApolloProvider>
    </React.Suspense>
  );
}

export default App;
