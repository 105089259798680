import React from "react";
import { Box, SimpleGrid, Image, Text, Flex } from "@chakra-ui/react";
import SabayD from "../../assets/logo/Sabay_Digicorp.png";
import "./footer.css";
import { useTranslation } from "react-i18next";
import service from "../../sdk";

function Footer() {
  const sabayGoogleMapUrl = "https://goo.gl/maps/QZxbHJmaKFT4SBZt8";
  const sabayPrivacyPolicyUrl = "https://sabay.com/privacy-policy/";

  const { t } = useTranslation();
  const handleOnClickLinkMatomo = (link) => {
    service.trackLink({
      href: link,
    });
  };

  return (
    <SimpleGrid
      className="s-footer"
      columns={{ sm: 1, md: 2 }}
      w="100%"
      minH="172px"
      bg="white"
    >
      <Box className="s-left-footer" p="16" pt="16px">
        <Flex className="s-logo">
          <Image src={SabayD} w="100px" h="50px" />
        </Flex>
        <Text>
          <a
            id="address_id"
            target="_blank"
            rel="noreferrer"
            href={sabayGoogleMapUrl}
            onClick={() => handleOnClickLinkMatomo(sabayGoogleMapUrl)}
          >
            {t(`sabay-address`)}
          </a>
          <br />
          {t(`Phone number`)}: 023 228 000
        </Text>
      </Box>
      <Box className="s-right-footer" textAlign="right" p="16" pt="16px">
        <Text
          cursor="pointer"
          color="#D63447"
          className="s-privacy-policy"
          mt="50px"
        >
          <a
            id="policy_id"
            target="_blank"
            rel="noreferrer"
            href={sabayPrivacyPolicyUrl}
            onClick={() => handleOnClickLinkMatomo(sabayPrivacyPolicyUrl)}
          >
            {t(`PRIVACY POLICY`)}
          </a>
        </Text>
        <Text>
          © {new Date().getFullYear()} {t("sabay-right")}
        </Text>
      </Box>
    </SimpleGrid>
  );
}

export default Footer;
