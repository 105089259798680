import React, { useState, useEffect } from "react";
import {
  Flex,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverCloseButton,
  Button,
  Text,
} from "@chakra-ui/react";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18next from "../../i18n";
import service from "../../sdk";

function HeaderPublic() {
  const [currentLang, setCurrentLang] = useState("kh");

  useEffect(() => {
    const lang = localStorage.getItem("language");
    if (lang !== null) {
      setCurrentLang(lang);
    }
  }, []);

  function changeLanguage(language) {
    if (language === "en") {
      i18next.changeLanguage("en");
      setCurrentLang("en");
      localStorage.setItem("language", "en");
    } else if (language === "kh") {
      i18next.changeLanguage("kh");
      setCurrentLang("kh");
      localStorage.setItem("language", "kh");
    }
  }

  const handleOnClickMatomo = (action, name) => {
    service.trackEvent({
      category: "Header",
      action: action,
      name: name,
    });
  };

  return (
    <Flex
      alignItems="flex-end"
      justifyContent="flex-end"
      h="46px"
      marginRight="16px"
    >
      <Box>
        <Popover gutter="3">
          <PopoverTrigger>
            <Button
              id="change-lang"
              _hover={{
                background: "unset",
              }}
              variant="outline"
              border="none"
              _focus={{
                border: "none",
              }}
              _active={{
                border: "none",
              }}
            >
              <Text
                color="#727272"
                fontWeight="500"
                cursor="pointer"
                fontSize="lg"
              >
                {currentLang === "kh" ? "ខ្មែរ" : "EN"}
              </Text>

              <FontAwesomeIcon
                icon={faChevronDown}
                style={{ fontSize: 13, marginLeft: "10px" }}
                color="#727272"
              />
            </Button>
          </PopoverTrigger>
          <PopoverContent
            w="69px"
            _focus={{ border: "none" }}
            _active={{ border: "none" }}
          >
            <PopoverBody p="4px">
              <PopoverCloseButton
                id="en_id"
                w="100%"
                h="41px"
                m="0"
                p="0"
                position="0"
                _active={{ border: "none" }}
                _focus={{ border: "none" }}
              >
                <Flex
                  w="100%"
                  h="100%"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => {
                    changeLanguage("en");
                    handleOnClickMatomo("tap", "Change Language to English");
                  }}
                >
                  <Text fontWeight="500" cursor="pointer" fontSize="lg">
                    EN
                  </Text>
                </Flex>
              </PopoverCloseButton>
              <PopoverCloseButton
                id="kh_id"
                w="100%"
                h="41px"
                m="0"
                p="0"
                position="0"
                _active={{ border: "none" }}
                _focus={{ border: "none" }}
              >
                <Flex
                  w="100%"
                  h="100%"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => {
                    changeLanguage("kh");
                    handleOnClickMatomo("tap", "Change Language to Khmer");
                  }}
                >
                  <Text fontWeight="500" cursor="pointer" fontSize="md">
                    ខ្មែរ
                  </Text>
                </Flex>
              </PopoverCloseButton>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    </Flex>
  );
}

export default HeaderPublic;
