const resellerGQL = window.RUNTIME_RESELLER_GQL_URL
  ? window.RUNTIME_RESELLER_GQL_URL
  : "https://reseller-api.master.mysabay.com/graphql";
const env = window.RUNTIME_NODE_ENV ?? "master";

const constants = {
  resellerGQL,
  env,
};

export default constants;
