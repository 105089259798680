import React from "react";

function Card(props) {
  return (
    <div style={styles.container}>
      {props?.children ? props?.children : null}
    </div>
  );
}

export default Card;

const styles = {
  container: {
    background: `var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box`,
    background: `#FFFFFF 0% 0% no-repeat padding-box`,
    boxShadow: `0px 0px 5px #00000029`,
    borderRadius: 10,
    overflow: "visible",
  },
};
