import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { createBrowserHistory } from "history";
import LayoutPublic from "./components/layout-public/layoutPublic";

// pages
import PageNotFound from "./containers/page-not-found/pageNotFound";
import RegisterReseller from "./containers/register-reseller/registerReseller";
// end pages

const defaultTitle = "Reseller - MySabay";

const Public = (props) => {
  return (
    <Route path={props.path}>
      <>
        <Helmet>
          <title>{props?.title ? props.title : defaultTitle}</title>
        </Helmet>
        <LayoutPublic>{props.children}</LayoutPublic>
      </>
    </Route>
  );
};

const Routes = () => {
  const browserHistory = createBrowserHistory();

  return (
    <Router history={browserHistory}>
      <Switch>
        <Public title="Register Reseller" path="/" exact={true}>
          <RegisterReseller />
        </Public>
        <Route path="/*" component={PageNotFound} />
      </Switch>
    </Router>
  );
};

export default Routes;
