/* eslint-disable indent */
import React, { useEffect, useCallback, useState } from "react";
import Dropzone from "react-dropzone";
import Card from "../../components/card/card";
import {
  Box,
  Text,
  Flex,
  Input,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  useDisclosure,
  ListItem,
  UnorderedList,
  Tooltip,
  CircularProgress,
  InputGroup,
  Textarea,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { faCheckCircle, faFileUpload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./registerReseller.css";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import service from "../../sdk";
import { useMutation, gql, useLazyQuery } from "@apollo/client";
import Resizer from "react-image-file-resizer";

const MUTATION_REGISTER = gql`
  mutation register(
    $mysabayUserId: Int!
    $name: String!
    $email: String!
    $phoneNumber: String!
    $address: String!
    $frontImage: String!
    $backImage: String!
  ) {
    signUp(
      input: {
        mysabayUserId: $mysabayUserId
        name: $name
        email: $email
        phoneNumber: $phoneNumber
        address: $address
        nationalIdCard: { frontImage: $frontImage, backImage: $backImage }
      }
    ) {
      id
      mysabayUserId
      name
      phoneNumber
      email
      address
      attachment {
        type
        file
        file_2
      }
    }
  }
`;

const QUERY_USER = gql`
  query getUser($id: Int!) {
    getMysabayProfile(id: $id) {
      userID
      familyName
      givenName
    }
  }
`;

function RegisterReseller() {
  const toast = useToast();
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenImageViewer,
    onOpen: onOpenImageViewer,
    onClose: onCloseImageViewer,
  } = useDisclosure();
  const [mysabayUserId, setMysabayUserId] = useState("");
  const [file1, setFile1] = useState("");
  const [file2, setFile2] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [mysabayName, setMysabayName] = useState("");

  const [
    getUser,
    { loading: getUserLoading, error: getUserError, data: getUserData },
  ] = useLazyQuery(QUERY_USER);

  const [
    registerSubmit,
    { loading: mutationLoading, error: mutationError, data: mutationData },
  ] = useMutation(MUTATION_REGISTER);

  useEffect(() => {
    service.trackPageView({
      documentTitle: "Register Reseller",
    });
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (getUserData?.getMysabayProfile) {
      if (
        getUserData?.getMysabayProfile?.familyName &&
        getUserData?.getMysabayProfile?.givenName
      ) {
        setMysabayName(
          `${getUserData?.getMysabayProfile?.familyName} ${getUserData?.getMysabayProfile?.givenName}`
        );
        setValue(
          "name",
          `${getUserData?.getMysabayProfile?.familyName} ${getUserData?.getMysabayProfile?.givenName}`
        );
      } else {
        setMysabayName("");
        setValue("name", "");
        toast({
          id: "error-name",
          title: t(
            "Your MySabay profile must be completed with your family and given name before you can sign up"
          ),
          duration: 9000,
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      }
    } else {
      setValue("name", "");
      setMysabayName("");
    }
  }, [getUserData]);

  const handleOnClickMatomo = (action, name) => {
    service.trackEvent({
      category: "Register Reseller",
      action: action,
      name: name,
    });
  };

  const useYupValidationResolver = (validationSchema) =>
    useCallback(
      async (data) => {
        data.name = mysabayName;
        try {
          const values = await validationSchema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors) {
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors, currentError) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? "validation",
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [validationSchema]
    );

  const validationSchema = yup.object({
    mySabayID: yup.string().required(t("MySabay ID is required.")),
    phoneNumber: yup
      .string()
      .min(9, t("Phone number must be at least 9 digits"))
      .max(10, t("Phone number is too long."))
      .required(t("Phone number is required.")),
    name: yup
      .string()
      .required(
        t(
          "Your MySabay profile must be completed with your family and given name before you can sign up"
        )
      ),
    email: yup
      .string()
      .required(t("Email is required."))
      .email(t("Email is not valid")),
    address: yup.string().required(t("Address is required.")),
    browseFiles: yup.mixed().required(t("At least 1 file is required.")),
  });

  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({ resolver });
  const onSubmit = async (data) => {
    if (!mutationLoading) {
      let file1 = "";
      let file2 = "";
      if (data?.browseFiles[0]) {
        file1 = await getBase64(data?.browseFiles[0]);
      }
      if (data?.browseFiles[1]) {
        file2 = await getBase64(data.browseFiles[1]);
      }
      registerSubmit({
        variables: {
          mysabayUserId: parseInt(data.mySabayID),
          name: data.name,
          email: data.email,
          phoneNumber: data.phoneNumber,
          address: data.address,
          frontImage: file1,
          backImage: file2,
        },
        onError: () => {},
      });
      onOpen();
    }
  };

  async function getBase64(file) {
    return new Promise((reslove, reject) => {
      try {
        Resizer.imageFileResizer(
          file,
          1080,
          "auto",
          "JPEG",
          90,
          0,
          (uri) => {
            reslove(uri);
          },
          "base64",
          200,
          200
        );
      } catch (err) {
        reject(err);
      }
    });
  }

  async function generatePreview(files) {
    setFile1("");
    setFile2("");
    setTimeout(async () => {
      if (files[0]) {
        const tmpFile1 = await genUrlPreview(files[0]);
        setFile1(tmpFile1);
      }
      if (files[1]) {
        const tmpFile2 = await genUrlPreview(files[1]);
        setFile2(tmpFile2);
      }
    }, 100);
  }

  async function genUrlPreview(file) {
    return new Promise((reslove, reject) => {
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          const binaryStr = reader.result;
          const blob = new Blob([binaryStr], { type: "image/jpeg" });
          const urlCreator = window.URL || window.webkitURL;
          const imageUrl = urlCreator.createObjectURL(blob);
          reslove(imageUrl);
        };
        reader.readAsArrayBuffer(file);
      } catch (err) {
        reject(err);
      }
    });
  }

  return (
    <>
      <Box style={styles.container}>
        <Box
          p="16px"
          pt="32px"
          pb="32px"
          maxW="550px"
          w="100%"
          overflow="hidden"
        >
          <Card>
            <Box p="6" w="100%">
              <Text
                mb="6"
                pl="4"
                pt="4"
                fontSize="27px"
                fontWeight="600"
                textTransform="uppercase"
                color="#14213D"
                id="title"
              >
                {t("Register as a reseller")}
              </Text>
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* MySabay input */}
                <Flex alignItems="center" p="4">
                  <Flex w="260px">
                    <Text>{t("MySabay ID")}</Text>
                    <Text color="#D63447">*</Text>
                  </Flex>
                  <Flex flexDirection="column" width="100%">
                    <Tooltip
                      hasArrow
                      label={t("Your MySabay ID")}
                      placement="right"
                    >
                      <InputGroup>
                        <Input
                          id="mysabay-id-input"
                          onClick={() =>
                            handleOnClickMatomo("tap", "MySabay ID Field")
                          }
                          type="number"
                          variant="outline"
                          size="lg"
                          _focus={{ borderColor: "#14213D" }}
                          {...register("mySabayID")}
                          onKeyDown={(evt) =>
                            evt.key === "e" && evt.preventDefault()
                          }
                          borderColor={
                            ((errors?.mySabayID && !mysabayUserId) ||
                              (getUserError && mysabayUserId)) &&
                            "red"
                          }
                          onBlur={() => {
                            if (mysabayUserId) {
                              getUser({
                                variables: {
                                  id: parseInt(mysabayUserId),
                                },
                              });
                            }
                          }}
                          onChange={(e) => {
                            setMysabayUserId(e.target.value);
                            setValue("mySabayID", e.target.value);
                          }}
                        />
                        <InputRightElement>
                          {getUserLoading && mysabayUserId && (
                            <CircularProgress
                              size="24px"
                              thickness="4px"
                              isIndeterminate
                              color="#14213D"
                              mt="8px"
                            />
                          )}
                        </InputRightElement>
                      </InputGroup>
                    </Tooltip>
                    {getUserError && mysabayUserId && (
                      <Text
                        id="error-check-mysabay-id"
                        color="red.400"
                        fontSize="12px"
                        mt="2"
                      >
                        {t(
                          getUserError?.graphQLErrors[0]?.message
                            ? (getUserError?.graphQLErrors[0]?.message).replace(
                                ".",
                                ""
                              )
                            : "something went wrong"
                        )}
                      </Text>
                    )}
                    {errors?.mySabayID && !mysabayUserId && (
                      <Text color="red.400" fontSize="12px">
                        {errors.mySabayID.message}
                      </Text>
                    )}
                  </Flex>
                </Flex>
                {/* MySabay input */}
                {/* name input */}
                <Flex alignItems="center" p="4" pt="0">
                  <Flex w="260px">
                    <Text>{t("Name")}</Text>
                    <Text color="#D63447">*</Text>
                  </Flex>
                  <Flex flexDirection="column" width="100%">
                    <Tooltip hasArrow label={t("Your Name")} placement="right">
                      <Input
                        id="name-input"
                        onClick={() => handleOnClickMatomo("tap", "Name Field")}
                        variant="outline"
                        disabled={true}
                        size="lg"
                        _focus={{ borderColor: "#14213D" }}
                        {...register("name")}
                        borderColor={errors?.name && !mysabayName && "red"}
                      />
                    </Tooltip>
                    {errors?.name && !mysabayName && (
                      <Text color="red.400" fontSize="12px">
                        {errors.name.message}
                      </Text>
                    )}
                  </Flex>
                </Flex>
                {/* end name input */}
                {/* show text with have name*/}
                {mysabayName && (
                  <Flex alignItems="center" mt="-3" p="4" pt="0">
                    <Flex w="260px"></Flex>
                    <Flex flexDirection="column" width="100%">
                      <Text fontSize="12px" color={styles.grayColor}>
                        {t(
                          "If you wish to change your name, you can update them through"
                        )}{" "}
                        <span
                          id="mysabay"
                          className="link"
                          onClick={() =>
                            window.open("https://mysabay.sabay.com")
                          }
                        >
                          Mysabay
                        </span>
                      </Text>
                    </Flex>
                  </Flex>
                )}
                {/* email input */}
                <Flex alignItems="center" p="4" pt="0">
                  <Flex w="260px">
                    <Text>{t("Email")}</Text>
                    <Text color="#D63447">*</Text>
                  </Flex>
                  <Flex flexDirection="column" width="100%">
                    <Tooltip hasArrow label={t("Your Email")} placement="right">
                      <Input
                        id="email-input"
                        onClick={() =>
                          handleOnClickMatomo("tap", "Email Field")
                        }
                        variant="outline"
                        size="lg"
                        _focus={{ borderColor: "#14213D" }}
                        {...register("email")}
                        borderColor={errors?.email && "red"}
                      />
                    </Tooltip>
                    {errors?.email && (
                      <Text color="red.400" fontSize="12px">
                        {errors.email.message}
                      </Text>
                    )}
                  </Flex>
                </Flex>
                {/* end email input */}
                {/* address input */}
                <Flex alignItems="center" p="4" pt="0">
                  <Flex w="260px">
                    <Text>{t("Address")}</Text>
                    <Text color="#D63447">*</Text>
                  </Flex>
                  <Flex flexDirection="column" width="100%">
                    <Tooltip hasArrow label={t("Address")} placement="right">
                      <Textarea
                        id="address-input"
                        onClick={() =>
                          handleOnClickMatomo("tap", "Address Field")
                        }
                        variant="outline"
                        size="lg"
                        _focus={{ borderColor: "#14213D" }}
                        {...register("address")}
                        borderColor={errors?.address && "red"}
                      />
                    </Tooltip>
                    {errors?.address && (
                      <Text color="red.400" fontSize="12px">
                        {errors.address.message}
                      </Text>
                    )}
                  </Flex>
                </Flex>
                {/* end address input */}
                {/* phone number input */}
                <Flex alignItems="center" p="4" pt="0">
                  <Flex w="260px">
                    <Text>{t("Phone number")}</Text>
                    <Text color="#D63447">*</Text>
                  </Flex>
                  <Flex flexDirection="column" width="100%">
                    <Controller
                      name="phoneNumber"
                      control={control}
                      defaultValue={false}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Tooltip
                          hasArrow
                          label={t("Your Phone Number")}
                          placement="right"
                        >
                          <div>
                            <PhoneInput
                              onClick={() =>
                                handleOnClickMatomo("tap", "Phone Number Field")
                              }
                              {...field}
                              disableCountryGuess={true}
                              disableCountryCode={true}
                              prefix={""}
                              country={"kh"}
                              onlyCountries={["kh"]}
                              placeholder=""
                              inputProps={{
                                name: "phone-number",
                                id: "phone-number-input",
                              }}
                              containerStyle={{
                                height: "48px",
                                width: "calc(100% + 20px)",
                                borderRadius: 10,
                                marginTop: 8,
                                width: "100%",
                                boxSizing: "border-box",
                              }}
                              inputStyle={{
                                height: "100%",
                                width: "100%",
                                border: `1px solid ${
                                  errors?.phoneNumber ? "red" : "#7979793b"
                                }`,
                                borderRadius: "none",
                                fontSize: "1.125rem",
                                fontFamily: "Rajdhani",
                                color: "16px",
                                fontWeight: 400,
                                paddingLeft: "15px",
                              }}
                              dropdownStyle={{
                                borderBottomRightRadius: 5,
                                borderBottomLeftRadius: 5,
                                fontFamily: "Rajdhani",
                              }}
                              buttonStyle={{
                                display: "none",
                              }}
                            />
                          </div>
                        </Tooltip>
                      )}
                    />

                    {errors?.phoneNumber && (
                      <Text color="red.400" fontSize="12px">
                        {errors.phoneNumber.message}
                      </Text>
                    )}
                  </Flex>
                </Flex>
                {/* end phone number input */}
                {/* attach file input */}
                <Controller
                  control={control}
                  name="browseFiles"
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Dropzone
                      onDrop={(e) => {
                        generatePreview(e);
                        setValue("browseFiles", e);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input
                            {...getInputProps()}
                            onBlur={onBlur}
                            onClick={() =>
                              handleOnClickMatomo("tap", "Browse Files Button")
                            }
                          />

                          <Flex p="4" pt="0" alignItems="center">
                            <Flex w="260px">
                              <Text>{t("National ID")}</Text>
                              <Text color="#D63447">*</Text>
                            </Flex>
                            <Flex width="100%" flexDirection="column">
                              <Tooltip
                                hasArrow
                                label={t("Upload your National ID here")}
                                placement="right"
                              >
                                <Flex
                                  cursor="pointer"
                                  _hover={{
                                    background: "#F4F4F4",
                                  }}
                                  width="calc(100% + 45px)"
                                  h="50px"
                                  justifyContent="center"
                                  alignItems="center"
                                  direction="row"
                                  borderRadius="15px"
                                  border={`1px dashed ${
                                    errors?.browseFiles && !file1
                                      ? "red"
                                      : "var(--chakra-colors-gray-200)"
                                  }`}
                                  style={{
                                    width: "100%",
                                    boxSizing: "border-box",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faFileUpload}
                                    style={{ fontSize: 18, margin: 7 }}
                                    color="#14213D"
                                  />
                                  <Text color="#14213D">
                                    {t("Browse Files")}
                                  </Text>
                                </Flex>
                              </Tooltip>
                              <UnorderedList paddingTop={value ? "20px" : "0"}>
                                {value &&
                                  value.map((f, index) => (
                                    <ListItem key={index} color="#727272">
                                      {f.name}
                                    </ListItem>
                                  ))}
                              </UnorderedList>
                              {errors?.browseFiles && !file1 && (
                                <Text color="red.400" fontSize="12px">
                                  {errors.browseFiles.message}
                                </Text>
                              )}
                            </Flex>
                          </Flex>
                        </div>
                      )}
                    </Dropzone>
                  )}
                />
                <Flex alignItems="center" p="4" pt="0">
                  <Flex w="260px"></Flex>
                  <Flex flexDirection="column" width="100%">
                    <Flex direction="row" mt="4">
                      {file1 && (
                        <Box
                          onClick={() => {
                            onOpenImageViewer();
                            setSelectedImage(file1);
                          }}
                          cursor="pointer"
                          mr="2"
                          p="2"
                          flex="1"
                          w="100%"
                          borderRadius="3px"
                          border="1px solid silver"
                        >
                          <img
                            src={file1}
                            style={{ width: "100%", height: "auto" }}
                          />
                        </Box>
                      )}
                      {file2 && (
                        <Box
                          onClick={() => {
                            onOpenImageViewer();
                            setSelectedImage(file2);
                          }}
                          cursor="pointer"
                          ml="2"
                          p="2"
                          flex="1"
                          w="100%"
                          borderRadius="3px"
                          border="1px solid silver"
                        >
                          <img
                            src={file2}
                            style={{ width: "100%", height: "auto" }}
                          />
                        </Box>
                      )}
                    </Flex>
                  </Flex>
                </Flex>

                <Text
                  textAlign="right"
                  fontSize="12px"
                  pr="4"
                  color={styles.grayColor}
                >
                  {t("Make sure you upload both front and back your ID card")}
                </Text>

                {/* end attach file input */}

                <Flex justifyContent="flex-end" mb="4">
                  <Button
                    id="submit-button"
                    onClick={handleOnClickMatomo("tap", "Submit Button")}
                    textTransform="uppercase"
                    mt="8"
                    mr="4"
                    fontWeight="600"
                    borderRadius="20px"
                    bg="#14213D"
                    color="white"
                    variant="solid"
                    _hover={{ bg: "#14213D" }}
                    pl="8"
                    pr="8"
                    type="submit"
                    disabled={
                      getUserError || getUserLoading || mutationLoading
                        ? true
                        : false
                    }
                  >
                    {t("Submit")}
                  </Button>
                </Flex>
              </form>
            </Box>
          </Card>
        </Box>
      </Box>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          if (mutationData?.signUp?.mysabayUserId) {
            window?.scrollTo(0, 0);
            window?.location?.reload();
          }
          onClose();
        }}
        closeOnOverlayClick={
          mutationError || mutationData?.signUp?.mysabayUserId ? true : false
        }
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              p="7"
              pb="0"
            >
              {mutationError && !mutationLoading && (
                <Text mt="4" color="red" id="register-error">
                  {mutationError?.graphQLErrors[0]?.message
                    ? t(
                        (mutationError?.graphQLErrors[0]?.message).replace(
                          ".",
                          ""
                        )
                      )
                    : t("something went wrong")}
                </Text>
              )}
              {!mutationError && mutationLoading && (
                <CircularProgress
                  size="100px"
                  thickness="4px"
                  isIndeterminate
                  color="#14213D"
                />
              )}
              {!mutationError && !mutationLoading && (
                <>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ fontSize: 70, margin: 4 }}
                    color="#14213D"
                  />
                  <Text
                    mt="4"
                    fontWeight="600"
                    color="#14213D"
                    fontSize="32px"
                    textTransform="uppercase"
                  >
                    {t("Completed")}
                  </Text>
                  <Text mt="4" color={styles.grayColor}>
                    {t(
                      "We have received your application. We will contact you once your application is approved."
                    )}
                    <br />
                    {t("You may close this window.")}
                  </Text>
                </>
              )}
            </Flex>
          </ModalBody>

          <ModalFooter>
            {/* <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenImageViewer} onClose={onCloseImageViewer}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              p="7"
              pb="0"
            >
              <img
                src={selectedImage}
                style={{ width: "100%", height: "auto" }}
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default RegisterReseller;

const styles = {
  container: {
    minHeight: `calc(100vh - 234px)`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  grayColor: "#a9a9a9",
};
