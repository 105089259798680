import { ApolloClient, InMemoryCache } from "@apollo/client";
import c from "../constants";

const client = new ApolloClient({
  uri: c.resellerGQL,
  cache: new InMemoryCache(),
  defaultOptions: {
    mutate: { errorPolicy: "all" },
  },
});

export default client;
